import { defineStore } from "pinia";
import { useApiCall } from "~/composables/useApiCall";
import { extractHostname } from "~/utils/ExtractHostname";
import { useSearchStore } from "~/store/search";
import MediaListState, {
  MediaListDropdown,
  MediaListItem,
  MediaListDomains,
  MediaListDomainItem,
  MediaLists,
} from "~/types/MediaListStateType";
import MediaListDropdownItem from "~/types/MediaListDropdownItemType";
import RequestOptions from "~/types/RequestOptionsType";

export const useMediaListStore = defineStore("mediaList", {
  state: (): MediaListState => ({
    mediaLists: [],
    mediaListDomains: [],
    mediaListMap: new Map(),
    mediaListDomainMap: new Map(),
    mediaListIdDomainMap: new Map(),
    includedMediaLists: [],
    includedMediaListsBackup: [],
    excludedMediaLists: [],
    excludedMediaListsBackup: [],
  }),

  getters: {
    getIncludedMediaLists(state) {
      return state.includedMediaLists;
    },
    getExcludedMediaLists(state) {
      return state.excludedMediaLists;
    },
    mediaListDropdownItems(state) {
      const dropdownItems: MediaListDropdown = [];
      state.mediaLists.forEach((mediaList: MediaListItem) => {
        if (
          !state.includedMediaLists.find((list) => list.id === mediaList.id) &&
          !state.excludedMediaLists.find((list) => list.id === mediaList.id)
        ) {
          dropdownItems.push({
            name: `${mediaList?.attributes?.name}
              (${mediaList?.relationships?.media_list_domains?.data?.length})`,
            id: mediaList.id,
          });
        }
      });
      return dropdownItems;
    },
    getMediaListDomainsForTML(state) {
      return (id: string) => {
        return state.mediaListDomains.filter(
          (mediaListDomain) =>
            mediaListDomain.relationships.media_list.data.id === id,
        );
      };
    },
  },

  actions: {
    setIncludedMediaLists(list: MediaListDropdown) {
      this.includedMediaLists = list;
      this.includedMediaListsBackup = list;
    },
    setExcludedMediaLists(list: MediaListDropdown) {
      this.excludedMediaLists = list;
      this.excludedMediaListsBackup = list;
    },
    mapMediaListDomains(mediaListDomains: MediaListDomains) {
      mediaListDomains.forEach((domainObj: MediaListDomainItem) => {
        // map media list domain names to their respective media list id
        const mediaListId = domainObj?.relationships?.media_list?.data?.id;
        this.mediaListDomainMap.set(
          domainObj?.attributes?.media_type === "web"
            ? extractHostname(domainObj?.attributes?.url)
            : domainObj?.attributes?.url,
          mediaListId,
        );

        // map media list domain id to their respective media list domain obj
        if (this.mediaListIdDomainMap.has(mediaListId)) {
          const arr = this.mediaListIdDomainMap.get(mediaListId);
          if (arr) {
            arr.push(domainObj?.attributes);
            this.mediaListIdDomainMap.set(mediaListId, arr);
          }
        } else {
          this.mediaListIdDomainMap.set(mediaListId, [domainObj?.attributes]);
        }
      });
    },
    mapMediaLists(mediaListArray: MediaLists) {
      // map the ids of each media list to their respective list names
      mediaListArray.forEach((listObj: MediaListItem) => {
        this.mediaListMap.set(listObj.id, listObj?.attributes?.name);
      });
    },
    addIncludedMediaList(dropdownItem: MediaListDropdownItem) {
      // Add tag to included media lists + remove from dropdown
      const savedSearchStore = useSearchStore();
      this.includedMediaLists.push(dropdownItem);
      savedSearchStore.articleSearchRequest();
    },

    removeIncludedMediaList(id: string) {
      // Remove tag from included media lists + add it back to dropdown
      const savedSearchStore = useSearchStore();
      this.includedMediaLists = this.includedMediaLists.filter(
        (dropdownItem: MediaListDropdownItem) => {
          return dropdownItem.id !== id;
        },
      );
      savedSearchStore.articleSearchRequest();
    },

    addExcludedMediaList(dropdownItem: MediaListDropdownItem) {
      // Add tag to excluded media lists + remove from dropdown
      const savedSearchStore = useSearchStore();
      this.excludedMediaLists.push(dropdownItem);
      savedSearchStore.articleSearchRequest();
    },

    removeExcludedMediaList(id: string) {
      // Remove tag from excluded media lists + add it back to dropdown
      const savedSearchStore = useSearchStore();
      this.setExcludedMediaLists(
        this.excludedMediaLists.filter(
          (dropdownItem: MediaListDropdownItem) => {
            return dropdownItem.id !== id;
          },
        ),
      );
      savedSearchStore.articleSearchRequest();
    },
    async fetchMediaLists() {
      const options: RequestOptions = {
        method: "GET",
      };
      const resp = await useApiCall("/media_lists", options);
      const responseData: MediaLists = resp.data._rawValue.data;
      this.mediaLists = responseData;
      this.mediaListDomains = resp.data._rawValue.included || [];
      this.mediaListIdDomainMap = new Map();

      this.mapMediaLists(this.mediaLists);
      this.mapMediaListDomains(this.mediaListDomains);
    },
    async createMediaList(listName: string) {
      const reqQuery = {
        data: {
          attributes: {
            name: listName,
            created_at: null,
          },
          type: "media-lists",
        },
      };
      const options: RequestOptions = { method: "POST", body: reqQuery };

      const resp = await useApiCall(`/media_lists`, options);

      if (resp?.error?.value?.data?.errors) {
        throw resp.error.value.data.errors[0].detail;
      }

      return resp;
    },
    async updateMediaListName(id: string, newListName: string) {
      const reqQuery = {
        data: {
          attributes: {
            name: newListName,
          },
          type: "media-lists",
        },
      };
      const options: RequestOptions = { method: "PATCH", body: reqQuery };

      const resp = await useApiCall(`/media_lists/${id}`, options);

      if (resp?.error?.value?.data?.errors) {
        throw resp.error.value.data.errors[0].detail;
      }

      const foundMediaList = this.mediaLists.find(
        (mediaList) => mediaList.id === id,
      );

      if (foundMediaList) {
        // Update the name property in the attributes field
        foundMediaList.attributes.name = newListName;
      }
    },
    async deleteTargetMediaList(id: string) {
      const options: RequestOptions = {
        method: "DELETE",
      };
      try {
        const resp = await useApiCall(`/media_lists/${id}`, options);

        // filter out the deleted mediaLists
        this.mediaLists = this.mediaLists.filter(
          (mediaList) => mediaList.id !== id,
        );
        return resp;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    async updateDomainName(id: string, newDomainName: string) {
      try {
        const reqQuery = {
          data: {
            attributes: {
              name: newDomainName,
            },
            type: "media-list-domains",
          },
        };
        const options: RequestOptions = { method: "PATCH", body: reqQuery };
        const resp = await useApiCall(`/media_list_domains/${id}`, options);
        await this.fetchMediaLists();

        return resp;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    async addTargetMediaListDomain(domain: Object) {
      const reqQuery = {
        data: {
          attributes: {
            media_type: domain.mediaType,
            url: domain.url,
          },
          relationships: {
            media_list: {
              data: {
                type: "media-lists",
                id: domain.mediaList,
              },
            },
          },
          type: "media-list-domains",
        },
      };
      const options: RequestOptions = {
        method: "POST",
        body: reqQuery,
      };
      const resp = await useApiCall(`/media_list_domains`, options);

      if (resp?.error?.value?.data?.errors) {
        throw resp.error.value.data.errors[0].detail;
      }

      this.mediaListDomains.push(resp.data._rawValue.data);
    },
    async deleteTargetMediaListDomain(id: string) {
      const options: RequestOptions = {
        method: "DELETE",
      };
      try {
        const resp = await useApiCall(`/media_list_domains/${id}`, options);

        // filter out the deleted mediaLists
        this.mediaListDomains = this.mediaListDomains.filter(
          (mediaList) => mediaList.id !== id,
        );
        return resp;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    resetMediaLists() {
      this.includedMediaLists = this.includedMediaListsBackup;
      this.excludedMediaLists = this.excludedMediaListsBackup;
    },
  },
});
