import { ElNotification } from "element-plus";

const emitNotification = ({
  status,
  successMessage,
  errorMessage,
}: {
  status: boolean;
  successMessage?: string;
  errorMessage?: string;
}) => {
  ElNotification({
    customClass: status ? "notification_success" : "notification_failure",
    dangerouslyUseHTMLString: true,
    message: status
      ? `<span class="notification_success_text">${successMessage}</span>`
      : `<span class="notification_failure_text">${errorMessage}</span>`,
    duration: 5000,
    showClose: false,
    type: status ? "success" : "error",
  });
};

export default emitNotification;
